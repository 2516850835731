import {FaDropbox} from 'react-icons/fa';
import {useEffect, useState} from 'react';
import {Space, Table} from 'antd';
import {Api} from '../services/api';
import {CONFIG} from '../config';
import {Link} from 'react-router-dom';
import moment from 'moment';

export default function Dashboard() {
  const [statistics, setStatistics] = useState({});

  const topStaffColumns = [
    {
      title: 'Tên NV',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mã NV',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Số hợp đồng',
      dataIndex: 'contract_counter',
      key: 'contract_counter',
      render: (_, record) => {
        return numberWithCommas(record['contract_counter']);
      },
    },
    {
      title: 'Tổng giá trị',
      dataIndex: 'contract_amount',
      key: 'contract_amount',
      render: (_, record) => {
        return numberWithCommas(record['contract_amount']);
      },
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record) => (
          <Space size="middle">
            <Link to={`/contracts?contract_related_staff=${record.id}`}>
              <a>Xem DS HĐ</a>
            </Link>
          </Space>
      ),
    },
  ];

  const commonContracts = [
    {
      title: 'Biển số xe',
      dataIndex: 'license_plate',
      key: 'license_plate',
      defaultSortOrder: 'descend',
      render: (_, record) => {
        return record['license_plate'];
      },
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'contract_related_staff.code',
      key: 'contract_related_staff.code',
      render: (_, record) => {
        if (record['contract_related_staff'] && record['contract_related_staff']['is_disabled'] === true) {
          return (
              <div className={'text-red-600'}>
                {record['contract_related_staff'].code}
              </div>
          )
        } else {
          return record['contract_related_staff']['code'];
        }
      },
    },
    {
      title: 'Mã nhân viên',
      dataIndex: 'contract_related_staff.name',
      key: 'contract_related_staff.name',
      render: (_, record) => {
        if (record['contract_related_staff'] && record['contract_related_staff']['is_disabled'] === true) {
          return (
              <div className={'text-red-600'}>
                {record['contract_related_staff'].name}
              </div>
          )
        } else {
          return record['contract_related_staff']['name'];
        }
      },
    },
    {
      title: 'Ngày hết hạn HĐ',
      dataIndex: 'contract_end_date',
      key: 'contract_end_date',
      render: (_, record) => {
        return moment(record['contract_end_date']).format('DD-MM-YYYY').valueOf();
      },
    },
    {
      title: 'Giá trị HĐ',
      dataIndex: 'contract_amount',
      key: 'contract_amount',
      render: (_, record) => {
        return numberWithCommas(record['contract_amount']);
      },
    }, {
      title: 'Trạng thái',
      dataIndex: 'contract_status',
      key: 'contract_status',
      render: (_, record) => {
        return CONFIG.mappingStatusToLabel[record['contract_status']];
      },
    },

    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record) => (
          <Space size="middle">
            <Link to={'/vehicles/edit/' + record.id}>
              <a>Xem chi tiết</a>
            </Link>
          </Space>
      ),
    },
  ];

  const waitApprovalVehicles = [
    {
      title: 'Biển số xe',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'descend',
      render: () => {
        return '30H-30510';
      },
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'related_staff.name',
      key: 'related_staff.name',
      render: (_, record) => {
        return record['related_staff'] ? record['related_staff']['name'] : '';
      },
    },
    {
      title: 'Mã nhân viên',
      dataIndex: 'related_staff.code',
      key: 'related_staff.code',
      render: (_, record) => {
        return record['related_staff'] ? record['related_staff']['code'] : '';
      },
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record) => (
          <Space size="middle">
            <Link to={'/vehicles/edit/' + record.id}>
              <a>Xem chi tiết</a>
            </Link>
          </Space>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      const res = await Api.init().get('/statistics', {
        params: {
          from: moment().startOf('month').toISOString(),
          to: moment().endOf('month').toISOString(),
        },
      });

      setStatistics(res.data);
    })();
  }, []);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
      <div className={'p-5'}>
        <div className={'flex flex-wrap'}>
          <div className={'w-full md:w-1/2 xl:w-1/3 p-3'}>
            <div className="bg-white border rounded shadow p-2">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded p-3 bg-green-600">
                    <FaDropbox color={'#FFF'} size={20}/>
                  </div>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h5 className="font-bold uppercase text-gray-500">Giá trị hợp đồng</h5>
                  <h3 className="font-bold text-3xl">{numberWithCommas(statistics.contractAmount || 0)} <span className="text-green-500"><i className="fas fa-caret-up"></i></span></h3>
                </div>
              </div>
            </div>
          </div>
          <div className={'w-full md:w-1/2 xl:w-1/3 p-3'}>
            <div className="bg-white border rounded shadow p-2">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded p-3 bg-green-600">
                    <FaDropbox color={'#FFF'} size={20}/>
                  </div>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h5 className="font-bold uppercase text-gray-500">Số lượng hợp đồng</h5>
                  <h3 className="font-bold text-3xl">{statistics.contractCounter || 0} <span className="text-green-500"><i className="fas fa-caret-up"></i></span></h3>
                </div>
              </div>
            </div>
          </div>
          <div className={'w-full md:w-1/2 xl:w-1/3 p-3'}>
            <div className="bg-white border rounded shadow p-2">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded p-3 bg-green-600">
                    <FaDropbox color={'#FFF'} size={20}/>
                  </div>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h5 className="font-bold uppercase text-gray-500">Số lượng khách hàng mới</h5>
                  <h3 className="font-bold text-3xl">{statistics.customerCounter || 0} <span className="text-green-500"><i className="fas fa-caret-up"></i></span></h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={'flex flex-wrap'}>
          <div className={'w-full xl:w-1/2 p-3'}>
            <div className="bg-white border rounded shadow p-2">
              <div className="flex flex-row items-center">
                <div className="flex-1 text-right md:text-center">
                  <h5 className="font-bold uppercase text-gray-500">Top nhân viên tháng</h5>
                  <Table
                      rowKey={'id'}
                      size={'small'}
                      dataSource={statistics.staffs}
                      columns={topStaffColumns}
                      pagination={{
                        position: ['bottomCenter', 'topCenter'],
                      }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={'w-full xl:w-1/2 p-3'}>
            <div className="bg-white border rounded shadow p-2">
              <div className="flex flex-row items-center">
                <div className="flex-1 text-right md:text-center">
                  <h5 className="font-bold uppercase text-gray-500">Phương tiện chờ duyệt</h5>
                  <Table
                      rowKey={'id'}
                      size={'small'}
                      dataSource={statistics.waitApprovalVehicles}
                      columns={waitApprovalVehicles}
                      pagination={{
                        position: ['topCenter', 'bottomCenter'],
                      }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={'w-full xl:w-2/2 p-3'}>
            <div className="bg-white border rounded shadow p-2">
              <div className="flex flex-row items-center">
                <div className="flex-1 text-right md:text-center">
                  <h5 className="font-bold uppercase text-gray-500">Hợp đồng sắp hết hạn</h5>
                  <Table
                      rowKey={'id'}
                      size={'small'}
                      dataSource={statistics.expiringContracts}
                      columns={commonContracts}
                      pagination={{
                        position: ['bottomCenter', 'topCenter'],
                      }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={'w-full xl:w-2/2 p-3'}>
            <div className="bg-white border rounded shadow p-2">
              <div className="flex flex-row items-center">
                <div className="flex-1 text-right md:text-center">
                  <h5 className="font-bold uppercase text-gray-500">Hợp đồng đã hết hạn</h5>
                  <Table
                      rowKey={'id'}
                      size={'small'}
                      dataSource={statistics.expiredContracts}
                      columns={commonContracts}
                      pagination={{
                        position: ['bottomCenter', 'topCenter'],
                      }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={'w-full xl:w-2/2 p-3'}>
            <div className="bg-white border rounded shadow p-2">
              <div className="flex flex-row items-center">
                <div className="flex-1 text-right md:text-center">
                  <h5 className="font-bold uppercase text-gray-500">Hợp đồng chờ duyệt</h5>
                  <Table
                      rowKey={'id'}
                      size={'small'}
                      dataSource={statistics.waitApprovalContracts}
                      columns={commonContracts}
                      pagination={{
                        position: ['bottomCenter', 'topCenter'],
                      }}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
  );
}
