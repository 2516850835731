import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Api} from '../../services/api';
import {Button, Checkbox, Form, Input, InputNumber, message, Select} from 'antd';
import {useForm} from 'antd/es/form/Form';
import {FaArrowCircleLeft} from 'react-icons/fa';
import {useHookQuery} from '../../hooks/use-hook-query';
import ImageGallery from '../../components/ImageGallery';
import moment from 'moment';
import {MinusCircleOutlined} from '@ant-design/icons';
import {ViDatePicker} from '../../components/ViDatePicker';

export default function ContractEditOrCreate() {
  const history = useHistory();
  const params = useParams();
  const [item, setItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [carModels, setCarModels] = useState([]);

  const [files, setFiles] = useState([]);

  const [form] = useForm();
  const hookQuery = useHookQuery();

  function getCarModels() {
    setIsLoading(true);
    Api.init().get('/car-models', {
      itemPerPage: 1000,
    }).then(res => {
      setCarModels(res.data.data);
    }).catch(() => {
      alert('Error while loading car models');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  function getStaffs() {
    setIsLoading(true);
    Api.init().get('/staffs', {
      itemPerPage: 1000,
    }).then(res => {
      setStaffs(res.data.data);
    }).catch(() => {
      alert('Error while loading staffs');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  function getCustomers() {
    setIsLoading(true);
    Api.init().get('/customers', {
      itemPerPage: 1000,
    }).then(res => {
      setCustomers(res.data.data);
    }).catch(() => {
      alert('Error while loading customers');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getCarModels();
    getStaffs();
    getCustomers();
  }, []);

  useEffect(() => {
    if (params.id) {
      setIsLoading(true);
      Api.init().get('/contracts/' + params.id).then(res => {
        setItem(res.data.data);

        [
          'registration_date_1',
          'registration_date_2',
          'registration_expire_date',
          'civil_insurance_expire_date',
          'contract_start_date',
          'contract_end_date',
        ].forEach(key => {
          if (res.data.data[key]) {
            res.data.data[key] = moment(res.data.data[key]);
          }
        });

        [
          'maintenance_histories',
          'insurance_histories',
        ].forEach(key => {
          if (!res.data.data[key]) {
            res.data.data[key] = [];
          }

          if (res.data.data[key] && res.data.data[key].length) {
            res.data.data[key].forEach((item, index) => {
              if (res.data.data[key][index]['date']) {
                res.data.data[key][index]['date'] = moment(res.data.data[key][index]['date']);
              }
            });
          }
        });

        form.setFieldsValue({
          ...res.data.data,
          car_model: res.data.data.car_model ? String(res.data.data.car_model.id) : null,
          related_staff: res.data.data.related_staff ? String(res.data.data.related_staff.id) : null,
          contract_related_staff: res.data.data.contract_related_staff ? String(res.data.data.contract_related_staff.id) : null,
          contract_related_customer: res.data.data.contract_related_customer ? String(res.data.data.contract_related_customer.id) : null,
        });

        setFiles([
          ...(res.data.data.images || []).map(item => {
            item.property_type = 'car';
            return item;
          }),
          ...(res.data.data.contract_images || []).map(item => {
            item.property_type = 'contract';
            return item;
          }),
        ]);
      }).catch((err) => {
        console.error('DEBUG err', err);
        alert('Error while loading car models');
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      form.setFieldsValue({
        car_company: hookQuery.get('carCompany') || null,
      });
    }
  }, [params.id]);

  async function handleUpdateGallery(newObjectFiles = [], recordId = null) {
    console.log('DEBUG', {
      newObjectFiles,
    });

    setIsLoading(true);
    Api.init().put('/contracts/' + recordId, {
      images: [
        ...files.filter(file => !file.inputFile).filter(item => item.property_type === 'car'), // Existed
        ...newObjectFiles.filter(item => item.property_type === 'car'), // Just upload
      ],
      contract_images: [
        ...files.filter(file => !file.inputFile).filter(item => item.property_type === 'contract'), // Existed
        ...newObjectFiles.filter(item => item.property_type === 'contract'), // Just upload
      ],
    }).then(() => {
      if (params.id) {
        message.success('Lưu thành công!');
      } else {
        message.success('Tạo thành công!');
      }

      if (hookQuery.get('refer') === 'contract') {
        history.push('/contracts');
      } else {
        history.push('/vehicles');
      }
    }).catch(() => {
      message.error('Lưu thất bại, vui lòng thử lại!');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const handleProcessFile = async (recordId) => {
    const newFiles = files.filter(item => item.inputFile);
    console.log('DEBUG newFiles', newFiles);
    if (!newFiles.length) {
      await handleUpdateGallery([], recordId);
    } else {
      const formData = new FormData();
      formData.append('data', '{}');
      newFiles.forEach(newFile => {
        formData.append('files.images', newFile.inputFile);
      });
      Api.init().post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((fileResponse) => {
        fileResponse.data.data = fileResponse.data.data.map((item, index) => {
          item['property_type'] = newFiles[index].property_type;
          return item;
        });
        handleUpdateGallery(fileResponse.data.data, recordId);
      }).catch(() => {
        alert('Error while upload');
      });
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    if (params.id) {
      Api.init().put('/contracts/' + params.id, {
        ...values,
      }).then((response) => {
        handleProcessFile(params.id);
      }).catch(() => {
        message.error('Lưu thất bại, vui lòng thử lại!');
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      Api.init().post('/contracts', {
        ...values,
      }).then((response) => {
        handleProcessFile(response.data.data.id);
      }).catch(() => {
        message.error('Tạo thất bại, vui lòng thử lại!');
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const watchContractStatus = Form.useWatch('contract_status', form);
  console.log('DEBUG watchContractStatus', watchContractStatus);

  const tableItem = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
  };

  return (
      <div className={'p-5'}>
        <div className={'flex items-center justify-between'}>
          <h1 className={'text-3xl mb-0 pr-5'}>{params.id ?
              'SỬA THÔNG TIN' :
              'THÊM THÔNG TIN'}</h1>
          <Button onClick={() => {
            if (hookQuery.get('refer') === 'contract') {
              history.push('/contracts');
            } else {
              history.push('/vehicles');
            }
          }} type={'primary'}
                  className={'!bg-green-600 !border-green-600'}>
            <div className={'flex space-between items-center'}>
              <FaArrowCircleLeft/>
              <div className={'ml-3'}>Quay lại</div>
            </div>
          </Button>
        </div>

        <div className="form-container" style={{
          background: '#FFF',
          padding: '25px',
          marginTop: '15px',
        }}>
          <Form
              form={form}
              name="basic"
              labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              initialValues={{remember: true}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <div className="row" style={{
              overflow: 'hidden',
            }}>
              <div className="left"
                   style={{
                     float: 'left',
                     width: '50%',
                     padding: '15px',
                     // background: '#e7e7e7',
                   }}
              >
                <h2 className={'text-3xl uppercase text-center'}>Thông tin hợp đồng</h2>

                <Form.Item name={'contract_status'} label="Trạng thái">
                  <Select
                      showSearch
                      optionFilterProp="children"
                      defaultValue={'NEW'}
                  >
                    <Select.Option value={'NEW'}>Chưa khởi tạo</Select.Option>
                    <Select.Option value={'DRAFT'}>Bản nháp</Select.Option>
                    <Select.Option value={'WAIT_APPROVAL'}>Chờ phê duyệt</Select.Option>
                    <Select.Option value={'REJECTED'}>Đã từ chối</Select.Option>
                    <Select.Option value={'APPROVED'}>Đã phê duyệt</Select.Option>
                  </Select>
                </Form.Item>

                <div style={{
                  display: ['NEW', '', null, undefined].includes(watchContractStatus) ? 'none' : 'block',
                }}>
                  <Form.Item name={'contract_related_customer'} label="Khách hàng liên kết">
                    <Select
                        showSearch
                        optionFilterProp="children"
                        defaultValue={''}
                    >
                      <Select.Option value={''}></Select.Option>
                      {customers.map(item => {
                        return (
                            <Select.Option value={String(item.id)}>{item.name} - {item.email}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item name={'contract_related_staff'} label="Nhân viên phụ trách">
                    <Select
                        showSearch
                        optionFilterProp="children"
                        defaultValue={''}
                    >
                      <Select.Option value={''}></Select.Option>
                      {staffs.map(item => {
                        return (
                            <Select.Option value={String(item.id)}>{item.name} - {item.code}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item label="Ngày bắt đầu hợp đồng" name="contract_start_date">
                    <ViDatePicker/>
                  </Form.Item>

                  <Form.Item label="Ngày hết hạn hợp đồng" name="contract_end_date">
                    <ViDatePicker/>
                  </Form.Item>

                  <Form.Item
                      label="Giá trị hợp đồng"
                      name="contract_amount"
                  >
                    <InputNumber
                        max={100000000}
                        formatter={value => `VNĐ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value ? value.replace(/\VNĐ\s?|(,*)/g, '') : ''}
                        style={{width: '100%'}}/>
                  </Form.Item>

                  <Form.Item
                      label="Ả̉nh thân vỏ xe"
                  >
                    <ImageGallery
                        type={'contract'}
                        items={files}
                        onChangeItems={(newItems) => {
                          setFiles(newItems);
                        }}
                    />
                  </Form.Item>

                  <Form.Item label="Ghi chú" name="contract_note">
                    <Input.TextArea/>
                  </Form.Item>
                </div>
              </div>
              <div className="right"
                   style={{
                     float: 'left',
                     width: '50%',
                     background: '#e7e7e7',
                     padding: '15px',
                   }}
              >
                <h2 className={'text-3xl uppercase text-center'}>Thông tin xe</h2>
                <Form.Item name={'status'} label="Trạng thái">
                  <Select
                      showSearch
                      optionFilterProp="children"
                  >
                    <Select.Option value={'DRAFT'}>Bản nháp</Select.Option>
                    <Select.Option value={'WAIT_APPROVAL'}>Chờ phê duyệt</Select.Option>
                    <Select.Option value={'REJECTED'}>Đã từ chối</Select.Option>
                    <Select.Option value={'APPROVED'}>Đã phê duyệt</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name={'car_model'} label="Dòng xe">
                  <Select
                      showSearch
                      optionFilterProp="children"
                      defaultValue={''}
                  >
                    <Select.Option value={''}></Select.Option>
                    {carModels.map(item => {
                      return (
                          <Select.Option value={String(item.id)}>{item.car_company.name} - {item.name}</Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item name={'related_staff'} label="Nhân viên tạo xe">
                  <Select
                      showSearch
                      optionFilterProp="children"
                      defaultValue={''}
                  >
                    <Select.Option value={''}></Select.Option>
                    {staffs.map(item => {
                      return (
                          <Select.Option value={String(item.id)}>{item.name} - {item.code}</Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item label="Biển số" name="license_plate">
                  <Input/>
                </Form.Item>

                <Form.Item label="Số khung" name="frame_number">
                  <Input/>
                </Form.Item>

                <Form.Item label="Số máy" name="machine_number">
                  <Input/>
                </Form.Item>

                <Form.Item label="Số sổ đăng kiểm" name="registration_number">
                  <Input/>
                </Form.Item>

                <Form.Item label="Hạn đăng kiểm" name="registration_expire_date">
                  <ViDatePicker/>
                </Form.Item>

                <Form.Item label="Hạn bảo hiểm dân sự" name="civil_insurance_expire_date">
                  <ViDatePicker/>
                </Form.Item>

                <Form.Item label="Tên chủ sở hữu" name="owner_name">
                  <Input/>
                </Form.Item>

                <Form.Item label={'Người liên quan'}>
                  <Form.List name="related_people">
                    {(fields, {add, remove}) => (
                        <>
                          {fields.map(field => (
                              <div key={field.key}>
                                <div className={'left'}>
                                  <Form.Item
                                      {...field}
                                      labelCol={{span: 6}}
                                      wrapperCol={{span: 18}}
                                      label="Tên"
                                      name={[field.name, 'name']}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                      {...field}
                                      labelCol={{span: 6}}
                                      wrapperCol={{span: 18}}
                                      label="SĐT"
                                      name={[field.name, 'phone_number']}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                      {...field}
                                      labelCol={{span: 6}}
                                      wrapperCol={{span: 18}}
                                      label="Địa chỉ"
                                      name={[field.name, 'address']}
                                  >
                                    <Input.TextArea/>
                                  </Form.Item>
                                </div>

                                <div className={'right'} style={{
                                  textAlign: 'right',
                                  marginBottom: '15px',
                                }}>
                                  <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                </div>
                              </div>
                          ))}

                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>Thêm mới</Button>
                          </Form.Item>
                        </>
                    )}
                  </Form.List>
                </Form.Item>

                <Form.Item label="Địa chỉ chủ sở hữu" name="owner_address">
                  <Input.TextArea/>
                </Form.Item>

                <Form.Item label="SĐT 1" name="phone_number_1">
                  <Input/>
                </Form.Item>

                <Form.Item label="SĐT 2" name="phone_number_2">
                  <Input/>
                </Form.Item>

                <Form.Item label="Ngày đăng ký 1" name="registration_date_1">
                  <ViDatePicker/>
                </Form.Item>

                <Form.Item label="Ngày đăng ký lần 2" name="registration_date_2">
                  <ViDatePicker/>
                </Form.Item>

                <Form.Item valuePropName="checked" label="Là xe kinh doanh" name="is_business_car">
                  <Checkbox/>
                </Form.Item>

                <Form.Item label={'Lịch sử bảo dưỡng'}>
                  <Form.List name="maintenance_histories">
                    {(fields, {add, remove}) => (
                        <>
                          {fields.map(field => (
                              <div key={field.key}>
                                <div className={'left'}>
                                  <Form.Item
                                      {...field}
                                      labelCol={{span: 6}}
                                      wrapperCol={{span: 18}}
                                      label="Ngày"
                                      name={[field.name, 'date']}
                                  >
                                    <ViDatePicker/>
                                  </Form.Item>
                                  <Form.Item
                                      {...field}
                                      labelCol={{span: 6}}
                                      wrapperCol={{span: 18}}
                                      label="Địa chỉ"
                                      name={[field.name, 'address']}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                      {...field}
                                      labelCol={{span: 6}}
                                      wrapperCol={{span: 18}}
                                      label="Ghi chú"
                                      name={[field.name, 'note']}
                                  >
                                    <Input.TextArea/>
                                  </Form.Item>
                                </div>

                                <div className={'right'} style={{
                                  textAlign: 'right',
                                  marginBottom: '15px',
                                }}>
                                  <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                </div>
                              </div>
                          ))}

                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>Thêm mới</Button>
                          </Form.Item>
                        </>
                    )}
                  </Form.List>
                </Form.Item>

                <Form.Item label={'Lịch sử chi trả bảo hiểm'}>
                  <Form.List name="insurance_histories">
                    {(fields, {add, remove}) => (
                        <>
                          {fields.map(field => (
                              <div key={field.key}>
                                <div className={'left'}>
                                  <Form.Item
                                      {...field}
                                      labelCol={{span: 6}}
                                      wrapperCol={{span: 18}}
                                      label="Ngày"
                                      name={[field.name, 'date']}
                                  >
                                    <ViDatePicker/>
                                  </Form.Item>
                                  <Form.Item
                                      {...field}
                                      labelCol={{span: 6}}
                                      wrapperCol={{span: 18}}
                                      label="Địa chỉ"
                                      name={[field.name, 'address']}
                                  >
                                    <Input.TextArea/>
                                  </Form.Item>
                                  <Form.Item
                                      {...field}
                                      labelCol={{span: 6}}
                                      wrapperCol={{span: 18}}
                                      label="Ghi chú"
                                      name={[field.name, 'note']}
                                  >
                                    <Input.TextArea/>
                                  </Form.Item>
                                </div>

                                <div className={'right'} style={{
                                  textAlign: 'right',
                                  marginBottom: '15px',
                                }}>
                                  <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                </div>
                              </div>
                          ))}

                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>Thêm mới</Button>
                          </Form.Item>
                        </>
                    )}
                  </Form.List>
                </Form.Item>

                <Form.Item
                    label="Hình ảnh xe"
                >
                  <ImageGallery
                      items={files}
                      type={'car'}
                      onChangeItems={(newItems) => {
                        setFiles(newItems);
                        console.log('DEBUG Handle change items', newItems);
                      }}
                  />
                </Form.Item>

                <Form.Item label="Ghi chú" name="note">
                  <Input.TextArea/>
                </Form.Item>
              </div>
            </div>
            <div className={'mt-2'}>
              <Form.Item wrapperCol={{offset: 0, span: 24}}>
                <Button block type="primary" htmlType="submit"
                        className={'!bg-green-600 !border-green-600'}>Lưu</Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
  );
}
