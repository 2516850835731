export const CONFIG = {
  itemPerPage: 10,
  // imageCdn: 'http://localhost:1337',
  // apiEndpoint: 'http://localhost:1337',
  imageCdn: 'https://baohiemoto-api.moha.team',
  apiEndpoint: 'https://baohiemoto-api.moha.team',
  // imageCdn: 'https://baohiemoto-api-prod.moha.team',
  // apiEndpoint: 'https://baohiemoto-api-prod.moha.team',
  mappingStatusToLabel: {
    APPROVED: 'Đã phê duyệt',
    REJECTED: 'Bị từ chối',
    NEW: 'Chưa khởi tạo',
    DRAFT: 'Bản nháp',
    WAIT_APPROVAL: 'Chờ phê duyệt',
  },
  errorCodes: {
    UNKNOWN: 'Lỗi chưa xác định khi call API',
    NAME_EXISTED: 'Tên đã tồn tại',
    ITEM_NOT_EXISTED: 'Dữ liệu không tồn tại',
  }
};
