import {Button, DatePicker, Form, Input, message, Select, Space, Table} from 'antd';
import {useEffect, useState} from 'react';
import {Api} from '../../services/api';
import {CONFIG} from '../../config';
import {Link, useHistory} from 'react-router-dom';
import {FaSearchPlus} from 'react-icons/fa';
import {useHookQuery} from '../../hooks/use-hook-query';
import moment from 'moment';

export default function ContractList() {
  const [items, setItems] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [currentFilterParams, setCurrentFilterParams] = useState({});

  const query = useHookQuery();

  // First time call data
  useEffect(() => {
    const queryParams = {};
    query.forEach((value, key) => {
      if (['contract_start_date', 'contract_end_date'].includes(key)) {
        value = value.split(',').map(item => moment(item));
      }

      queryParams[key] = value;
    });
    form.setFieldsValue(queryParams);
    setCurrentFilterParams(queryParams);

    getData({
      page: 1,
      orderBy: sortField,
      orderType: sortOrder,
      ...JSON.parse(JSON.stringify(queryParams)),
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('descend');

  const history = useHistory();

  const columns = [
    {
      title: 'Biển số',
      dataIndex: 'license_plate',
      key: 'license_plate',
      sorter: true,
    },
    {
      title: 'Nhân viên phụ trách',
      dataIndex: 'contract_related_staff',
      key: 'contract_related_staff',
      render: (_, record) => {
        if (record['contract_related_staff'] && record['contract_related_staff']['is_disabled'] === true) {
          return (
              <div className={'text-red-600'}>
                {`${record['contract_related_staff'].name} - ${record['contract_related_staff'].code || ''}`}
              </div>
          )
        } else {
          return record['contract_related_staff'] ? `${record['contract_related_staff'].name} - ${record['contract_related_staff'].code || ''}` : 'Chưa chỉ định';
        }
      },
    },
    {
      title: 'Trạng thái HĐ',
      dataIndex: 'contract_status',
      key: 'contract_status',
      sorter: true,
      render: (_, record) => {
        return CONFIG.mappingStatusToLabel[record['contract_status']];
      },
    },
    {
      title: 'Ngày bắt đầu HĐ',
      dataIndex: 'contract_start_date',
      key: 'contract_start_date',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (_, record) => {
        if (record['contract_start_date']) {
          return moment(record['contract_start_date']).format('DD-MM-YYYY').valueOf();
        }
      },
    },
    {
      title: 'Ngày kết thúc HĐ',
      dataIndex: 'contract_end_date',
      key: 'contract_end_date',
      sorter: true,
      render: (_, record) => {
        if (record['contract_end_date']) {
          return moment(record['contract_end_date']).format('DD-MM-YYYY').valueOf();
        }
      },
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 150,
      render: (_, record) => (
          <Space size="middle">
            <Link to={'/vehicles/edit/' + record.id + '?refer=contract'}>
              <a>Sửa xe + HĐ</a>
            </Link>
          </Space>
      ),
    },
  ];

  function getData(params) {
    if (!params.orderType) {
      delete params.orderBy;
    }

    if (!params.contract_status) {
      params.contract_status = null;
    }

    setIsLoading(true);
    Api.init().get('/contracts', {params}).then(res => {
      setItems(res.data.data);
      console.log('DEBUG res.data.count', res.data.count);
      setTotalItems(res.data.total);
    }).catch(() => {
      alert('Error while loading car models');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  function getCarModels() {
    setIsLoading(true);
    Api.init().get('/car-models', {
      itemPerPage: 1000,
    }).then(res => {
      setCarModels(res.data.data);
    }).catch(() => {
      alert('Error while loading car models');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  function getStaffs() {
    setIsLoading(true);
    Api.init().get('/staffs', {
      itemPerPage: 1000,
    }).then(res => {
      setStaffs(res.data.data);
    }).catch(() => {
      alert('Error while loading staffs');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  function getCustomers() {
    setIsLoading(true);
    Api.init().get('/customers', {
      itemPerPage: 1000,
    }).then(res => {
      setCustomers(res.data.data);
    }).catch(() => {
      alert('Error while loading customers');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getCarModels();
    getStaffs();
    getCustomers();
  }, []);

  const handleTableChange = (
      pagination,
      filters,
      sorter,
  ) => {
    setSortField(sorter.field);
    setSortOrder(sorter.order);

    getData({
      page: pagination.current,
      orderBy: sorter.field,
      orderType: sorter.order,
      ...currentFilterParams,
    });
  };

  const [form] = Form.useForm();

  function onFinishSearch(values) {
    const clone = JSON.parse(JSON.stringify(values));
    ['contract_start_date', 'contract_end_date'].forEach(key => {
      if (clone[key] && clone[key].length) {
        try {
          clone[key][0] = moment(clone[key][0]).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString();
          clone[key][1] = moment(clone[key][1]).set({hour: 23, minute: 59, second: 59, millisecond: 0}).toISOString();
        } catch (e) {
          console.log('DEBUG E', e);
        }
      }
    });

    Object.keys(clone).forEach(key => {
      if (clone[key] === undefined) {
        clone[key] = '';
      }
    });

    setCurrentFilterParams(clone);
    const url = new URLSearchParams(clone);
    history.push(`/contracts?${url}`);

    getData({
      page: 1,
      orderBy: sortField,
      orderType: sortOrder,
      ...clone,
    });
  }

  return (
      <div className={'p-5'}>
        <div className={'flex items-center justify-between'}>
          <h1 className={'text-3xl mb-0 pr-5'}>DANH SÁCH</h1>
        </div>

        <div className={'p-5 bg-white mt-5'}>
          <div className={'mb-5'}>
            <Form
                initialValues={{
                  contract_status: ''
                }}
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                layout={'horizontal'}
                form={form}
                onFinish={onFinishSearch}
            >
              <div className="grid grid-cols-4 gap-4">
                <div>
                  <Form.Item name={'license_plate'} label="Biển số">
                    <Input/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'frame_number'} label="Số khung">
                    <Input/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'machine_number'} label="Số máy">
                    <Input/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'phone_number'} label="Số điện thoại">
                    <Input/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'contract_status'} label="Trạng thái HĐ">
                    <Select
                        showSearch
                        optionFilterProp="children"
                    >
                      <Select.Option value={''}>Tất cả</Select.Option>
                      <Select.Option value={'DRAFT'}>Bản nháp</Select.Option>
                      <Select.Option value={'WAIT_APPROVAL'}>Chờ phê duyệt</Select.Option>
                      <Select.Option value={'REJECTED'}>Đã từ chối</Select.Option>
                      <Select.Option value={'APPROVED'}>Đã phê duyệt</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'contract_related_staff'} label="Nhân viên phụ trách">
                    <Select
                        showSearch
                        optionFilterProp="children"
                        defaultValue={''}
                    >
                      <Select.Option value={''}>Tất cả</Select.Option>
                      {staffs.map(item => {
                        return (
                            <Select.Option value={String(item.id)}>{item.name} - {item.code || ''}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'contract_related_customer'} label="Tài khoản khách hàng">
                    <Select
                        showSearch
                        optionFilterProp="children"
                        defaultValue={''}
                    >
                      <Select.Option value={''}>Tất cả</Select.Option>
                      {customers.map(item => {
                        return (
                            <Select.Option value={String(item.id)}>{item.name} - {item.email}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'car_model'} label="Dòng xe">
                    <Select
                        showSearch
                        optionFilterProp="children"
                        defaultValue={''}
                    >
                      <Select.Option value={''}>Tất cả</Select.Option>
                      {carModels.map(item => {
                        return (
                            <Select.Option value={String(item.id)}>{item.car_company.name} - {item.name}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name="contract_start_date" label={'Ngày bắt đầu HĐ trong khoảng'}>
                    <DatePicker.RangePicker/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name="contract_end_date" label={'Ngày kết thúc HĐ trong khoảng'}>
                    <DatePicker.RangePicker/>
                  </Form.Item>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4">
                <div></div>
                <div></div>
                <div></div>
                <div>
                  <Button block htmlType={'submit'} type="primary" className={'!bg-green-600 !border-green-600'}>
                    <div className={'flex space-between items-center justify-center'}>
                      <FaSearchPlus/>
                      <div className={'ml-1'}>Tìm kiếm</div>
                    </div>
                  </Button>
                </div>
              </div>
            </Form>
          </div>


          <Table
              onChange={handleTableChange}
              sortField={sortField}
              sortOrder={sortOrder}
              // loading={isLoading}
              rowKey={'id'}
              size={'small'}
              dataSource={items}
              columns={columns}
              pagination={{
                position: ['bottomCenter'],
                pageSize: CONFIG.itemPerPage,
                total: totalItems,
                size: 'small',
                current: currentPage,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                },
              }}
          />
        </div>
      </div>
  );
}
