import {message} from 'antd';
import {CONFIG} from './config';

export const HELPER = {
  startLoading: () => {
    window['counterLoading'] = (window['counterLoading'] || 0) + 1;
  },
  endLoading: () => {
    if (window['counterLoading']) {
      window['counterLoading'] = window['counterLoading'] - 1;
    }
  },
  handleApiError: (error) => {
    try {
      message.error(CONFIG.errorCodes[error.response.data.error_code || 'UNKNOWN']);
    } catch (e) {
      message.error(CONFIG.errorCodes['UNKNOWN']);
    }
    console.error(error);
  },
};
