import {Button, Form, message, Popconfirm, Select, Space, Table} from 'antd';
import {useEffect, useState} from 'react';
import {Api} from '../../services/api';
import {CONFIG} from '../../config';
import {useHistory} from 'react-router-dom';
import {FaCheck, FaPlusCircle, FaRegFileWord, FaSearchPlus, FaTimesCircle} from 'react-icons/fa';

export default function CustomerList() {
  const [items, setItems] = useState([]);
  const [searchedData, setSearchedData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('descend');

  const history = useHistory();

  async function handleDelete(id) {
    Api.init().delete('/customers/' + id).then(() => {
      message.success('Xóa thành công');
      getData({
        page: 1,
        orderBy: sortField,
        orderType: sortOrder,
        status: searchedData['status'] || '',
      });
    }).catch(() => {
      message.error('Xóa thất bại, vui lòng thử lại!');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    setCurrentPage(1); // Reset to 1

    getData({
      page: 1,
      orderBy: sortField,
      orderType: sortOrder,
      status: searchedData['status'] || '',
    });
  }, [searchedData]);

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      // render: (_, record) => {
      //   return record['car_company'].name;
      // },
    },
    {
      title: 'SĐT',
      dataIndex: 'phone_number',
      key: 'phone_number',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'is_disabled',
      key: 'is_disabled',
      sorter: true,
      render: (_, record) => {
        if (record['is_disabled']) {
          return <div className={'flex items-center'}><FaTimesCircle class={'text-red-600'}/> <span className={'pl-2'}>Đã vô hiệu hóa</span></div>;
        }
        return <div className={'flex items-center'}><FaCheck class={'text-green-600'}/> <span className={'pl-2'}>Đang kích hoạt</span></div>;
      },
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (_, record) => {
        return new Date(record['createdAt']).toLocaleString();
      },
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 200,
      render: (_, record) => (
          <Space size="middle">
            <a onClick={() => {
              history.push('/customers/edit/' + record.id);
            }}>Sửa</a>

            <Popconfirm placement="left"
                        title={'Bạn chắc chắn muốn xóa chứ'}
                        onConfirm={async () => {
                          await handleDelete(record.id);
                        }} okText="Đồng ý" cancelText="Hủy">
              <a>Xóa</a>
            </Popconfirm>

            <a className={'flex items-center'} onClick={() => {
              history.push('/contracts?contract_related_customer=' + record.id);
            }}>
              <FaRegFileWord/>
              <span className={'ml-1'}>Xem HĐ</span>
            </a>
          </Space>
      ),
    },
  ];

  function getData(params) {
    if (!params.orderType) {
      delete params.orderBy;
    }

    setIsLoading(true);
    Api.init().get('/customers', {params}).then(res => {
      setItems(res.data.data);
      console.log('DEBUG res.data.count', res.data.count);
      setTotalItems(res.data.total);
    }).catch(() => {
      alert('Error while loading car models');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const handleTableChange = (
      pagination,
      filters,
      sorter,
  ) => {
    setSortField(sorter.field);
    setSortOrder(sorter.order);

    getData({
      page: pagination.current,
      orderBy: sorter.field,
      orderType: sorter.order,
      status: searchedData['status'] || '',
    });
  };

  const [form] = Form.useForm();

  function onFinishSearch(values) {
    setSearchedData({
      ...values,
    });
  }

  return (
      <div className={'p-5'}>
        <div className={'flex items-center justify-between'}>
          <h1 className={'text-3xl mb-0 pr-5'}>DANH SÁCH</h1>
          <Button onClick={() => {
            history.push(`/customers/edit`);
          }} type={'primary'}
                  className={'!bg-green-600 !border-green-600'}>
            <div className={'flex space-between items-center'}>
              <FaPlusCircle/>
              <div className={'ml-3'}>Tạo mới</div>
            </div>
          </Button>
        </div>

        <div className={'p-5 bg-white mt-5'}>

          <div className={'mb-5'} style={{
            // width: '400px'
          }}>
            <Form
                initialValues={{
                  status: searchedData['status'] || '',
                }}
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                layout={'horizontal'}
                form={form}
                onFinish={onFinishSearch}
            >
              <div className="grid grid-cols-4 gap-4">
                <div>
                  <Form.Item name={'status'} label="Trạng thái tài khoản">
                    <Select
                        showSearch
                        optionFilterProp="children"
                        defaultValue={''}
                    >
                      <Select.Option value={''}>Tất cả</Select.Option>
                      <Select.Option value={'1'}>
                        <div className={'flex items-center'}><FaCheck class={'text-green-600'}/> <span className={'pl-2'}>Đang kích hoạt</span></div>
                      </Select.Option>
                      <Select.Option value={'0'}>
                        <div className={'flex items-center'}><FaTimesCircle class={'text-red-600'}/> <span className={'pl-2'}>Đã vô hiệu hóa</span></div>
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4">
                <div></div>
                <div></div>
                <div></div>
                <div>
                  <Button block htmlType={'submit'} type="primary" className={'!bg-green-600 !border-green-600'}>
                    <div className={'flex space-between items-center justify-center'}>
                      <FaSearchPlus/>
                      <div className={'ml-1'}>Tìm kiếm</div>
                    </div>
                  </Button>
                </div>
              </div>
            </Form>
          </div>


          <Table
              onChange={handleTableChange}
              sortField={sortField}
              sortOrder={sortOrder}
              // loading={isLoading}
              rowKey={'id'}
              size={'small'}
              dataSource={items}
              columns={columns}
              pagination={{
                position: ['bottomCenter'],
                pageSize: CONFIG.itemPerPage,
                total: totalItems,
                size: 'small',
                current: currentPage,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                },
              }}
          />
        </div>
      </div>
  );
}
