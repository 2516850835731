import {Button, Form, Input, message, Modal, Popconfirm, Select, Space, Table} from 'antd';
import {useEffect, useRef, useState} from 'react';
import {Api} from '../../services/api';
import {CONFIG} from '../../config';
import {useHistory} from 'react-router-dom';
import {FaDownload, FaPlusCircle, FaSearchPlus, FaTools} from 'react-icons/fa';
import {useHookQuery} from '../../hooks/use-hook-query';

const XLSX = require('xlsx');

export default function ContractList() {
  const [items, setItems] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [currentFilterParams, setCurrentFilterParams] = useState({});

  const query = useHookQuery();

  // First time call data
  useEffect(() => {
    const queryParams = {};
    query.forEach((value, key) => {
      queryParams[key] = value;
    });
    form.setFieldsValue(queryParams);
    setCurrentFilterParams(queryParams);

    getData({
      page: 1,
      orderBy: sortField,
      orderType: sortOrder,
      ...queryParams,
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('descend');
  const [importItems, setImportItems] = useState([]);

  const history = useHistory();

  async function handleDelete(id) {
    Api.init().delete('/vehicles/' + id).then(() => {
      message.success('Xóa thành công');
      getData({
        page: 1,
        orderBy: sortField,
        orderType: sortOrder,
        ...currentFilterParams,
      });
    }).catch(() => {
      message.error('Xóa thất bại, vui lòng thử lại!');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const columns = [
    {
      title: 'Biển số',
      dataIndex: 'license_plate',
      key: 'license_plate',
      sorter: true,
      // render: (_, record) => {
      //   return record['car_company'].name;
      // },
    },
    {
      title: 'Số khung',
      dataIndex: 'frame_number',
      key: 'frame_number',
      sorter: true,
    },
    {
      title: 'Số máy',
      dataIndex: 'machine_number',
      key: 'machine_number',
      sorter: true,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (_, record) => {
        return CONFIG.mappingStatusToLabel[record['status']];
      },
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (_, record) => {
        return new Date(record['createdAt']).toLocaleString();
      },
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 150,
      render: (_, record) => (
          <Space size="middle">
            <a onClick={() => {
              history.push('/vehicles/edit/' + record.id);
            }}>Sửa</a>

            <Popconfirm placement="left"
                        title={'Bạn chắc chắn muốn xóa chứ'}
                        onConfirm={async () => {
                          await handleDelete(record.id);
                        }} okText="Đồng ý" cancelText="Hủy">
              <a>Xóa</a>
            </Popconfirm>
          </Space>
      ),
    },
  ];

  function getData(params) {
    if (!params.orderType) {
      delete params.orderBy;
    }

    setIsLoading(true);
    Api.init().get('/vehicles', {params}).then(res => {
      setItems(res.data.data);
      console.log('DEBUG res.data.count', res.data.count);
      setTotalItems(res.data.total);
    }).catch(() => {
      alert('Error while loading car models');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  function getCarModels() {
    setIsLoading(true);
    Api.init().get('/car-models', {
      itemPerPage: 1000,
    }).then(res => {
      setCarModels(res.data.data);
    }).catch(() => {
      alert('Error while loading car models');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  function getStaffs() {
    setIsLoading(true);
    Api.init().get('/staffs', {
      itemPerPage: 1000,
    }).then(res => {
      setStaffs(res.data.data);
    }).catch(() => {
      alert('Error while loading staffs');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getCarModels();
    getStaffs();
  }, []);

  const handleTableChange = (
      pagination,
      filters,
      sorter,
  ) => {
    setSortField(sorter.field);
    setSortOrder(sorter.order);

    getData({
      page: pagination.current,
      orderBy: sorter.field,
      orderType: sorter.order,
      ...currentFilterParams,
    });
  };

  const [form] = Form.useForm();

  function onFinishSearch(values) {
    Object.keys(values).forEach(key => {
      if (values[key] === undefined) {
        values[key] = '';
      }
    });

    setCurrentFilterParams(values);
    const url = new URLSearchParams(values);
    history.push(`/vehicles?${url}`);

    getData({
      page: 1,
      orderBy: sortField,
      orderType: sortOrder,
      ...values,
    });
  }

  const inputExel = useRef(null);
  const formExel = useRef(null);

  useEffect(() => {
    inputExel.current.addEventListener('change', event => {
      event.preventDefault();
      const input = inputExel.current.files[0];
      const reader = new FileReader();

      reader.onload = function(e) {
        const data = e.target.result;

        setTimeout(() => {
          formExel.current.reset();
        }, 1000);

        const workbook = XLSX.read(data, {
          type: 'binary',
        });

        workbook.SheetNames.forEach(function(sheetName) {
          // Here is your object
          const items = XLSX.utils['sheet_to_row_object_array'](workbook.Sheets[sheetName]);
          console.log('DEBUG get items', items);
          setImportItems(items);
        });
      };

      reader.readAsBinaryString(input);
    });
  }, []);

  const exportImportResults = () => {
    const ws = XLSX.utils.json_to_sheet(importItems);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'IMPORT_RESULTS');
    XLSX.writeFileXLSX(wb, 'IMPORT_RESULTS.xlsx');
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const importColumns = [
    {
      title: 'Biển số',
      dataIndex: 'license_plate',
      key: 'license_plate',
    },
    {
      title: 'Số khung',
      dataIndex: 'frame_number',
      key: 'frame_number',
    },
    {
      title: 'Số máy',
      dataIndex: 'machine_number',
      key: 'machine_number',
    },
    {
      title: 'Dòng xe',
      dataIndex: 'car_model',
      key: 'car_model',
    },
    {
      title: 'Tên chủ xe',
      dataIndex: 'owner_name',
      key: 'owner_name',
    },
    {
      title: 'Địa chỉ chủ xe',
      dataIndex: 'owner_address',
      key: 'owner_address',
    },
    {
      title: 'SĐT',
      dataIndex: 'phone_number_1',
      key: 'phone_number_1',
    },
    {
      title: 'Ngày đăng kí xe',
      dataIndex: 'registration_date_1',
      key: 'registration_date_1',
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 150,
      render: (_, record) => (
          <Space size="middle">
            <a onClick={() => {
              history.push('/vehicles/edit/' + record.id);
            }}>Sửa</a>

            <Popconfirm placement="left"
                        title={'Bạn chắc chắn muốn xóa chứ'}
                        onConfirm={async () => {
                          await handleDelete(record.id);
                        }} okText="Đồng ý" cancelText="Hủy">
              <a>Xóa</a>
            </Popconfirm>
          </Space>
      ),
    },
  ];

  useEffect(() => {
    if (importItems && importItems.length) {
      setIsModalOpen(true);
    }
  }, [importItems]);

  return (
      <div className={'p-5'}>
        <Modal
            maskClosable={false}
            keyboard={false}
            width={'100%'} okText={'Bắt đầu nhập dữ liệu'} title="Vui lòng kiểm tra dữ liệu trước khi import" open={isModalOpen} onOk={() => {
          alert('Xử lý import');
        }} onCancel={() => {
          setIsModalOpen(false);
          setImportItems([]);
        }}>
          {importItems && importItems.length && (
              <Table
                  rowKey={'id'}
                  size={'small'}
                  dataSource={importItems}
                  columns={importColumns}
                  pagination={{
                    position: ['topCenter', 'bottomCenter'],
                    pageSize: 100000000,
                    // total: totalItems,
                    // size: 'small',
                    // current: currentPage,
                    // onChange: (page, pageSize) => {
                    //   setCurrentPage(page);
                    // },
                  }}
              />
          )}
        </Modal>

        <div className={'flex items-center justify-between'}>
          <h1 className={'text-3xl mb-0 pr-5'}>DANH SÁCH</h1>
          <div className="right">
            <Button onClick={() => {
              window.open('/SAMPLE_IMPORT.xlsx', '_new');
            }} type={'primary'}
                    className={'!bg-green-600 !border-green-600 mr-5'}>
              <div className={'flex space-between items-center'}>
                <FaDownload/>
                <div className={'ml-3'}>Tải file import mẫu</div>
              </div>
            </Button>

            <Button onClick={() => {
              inputExel.current.click();
            }} type={'primary'}
                    className={'!bg-green-600 !border-green-600 mr-5'}>
              <div className={'flex space-between items-center'}>
                <FaTools/>
                <div className={'ml-3'}>Nhập từ Exel</div>
              </div>

              <form ref={formExel}>
                <input style={{display: 'none'}} ref={inputExel} type="file" accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}/>
              </form>
            </Button>
            <Button onClick={() => {
              history.push(`/vehicles/edit`);
            }} type={'primary'}
                    className={'!bg-green-600 !border-green-600'}>
              <div className={'flex space-between items-center'}>
                <FaPlusCircle/>
                <div className={'ml-3'}>Tạo mới</div>
              </div>
            </Button>
          </div>
        </div>

        <div className={'p-5 bg-white mt-5'}>

          <div className={'mb-5'}>
            <Form
                initialValues={{
                  status: '',
                  car_model: ''
                }}
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                layout={'horizontal'}
                form={form}
                onFinish={onFinishSearch}
            >
              <div className="grid grid-cols-4 gap-4">
                <div>
                  <Form.Item name={'license_plate'} label="Biển số">
                    <Input/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'frame_number'} label="Số khung">
                    <Input/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'machine_number'} label="Số máy">
                    <Input/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'phone_number'} label="Số điện thoại">
                    <Input/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'status'} label="Trạng thái">
                    <Select
                        showSearch
                        optionFilterProp="children"
                    >
                      <Select.Option value={''}>Tất cả</Select.Option>
                      <Select.Option value={'DRAFT'}>Bản nháp</Select.Option>
                      <Select.Option value={'WAIT_APPROVAL'}>Chờ phê duyệt</Select.Option>
                      <Select.Option value={'REJECTED'}>Đã từ chối</Select.Option>
                      <Select.Option value={'APPROVED'}>Đã phê duyệt</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'related_staff'} label="Nhân viên tạo xe">
                    <Select
                        showSearch
                        optionFilterProp="children"
                        defaultValue={''}
                    >
                      <Select.Option value={''}>Tất cả</Select.Option>
                      {staffs.map(item => {
                        return (
                            <Select.Option key={item.id} value={String(item.id)}>{item.name} - {item.code || ''}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'car_model'} label="Dòng xe">
                    <Select
                        showSearch
                        optionFilterProp="children"
                    >
                      <Select.Option value={''}>Tất cả</Select.Option>
                      {carModels.map(item => {
                        return (
                            <Select.Option key={item.id} value={String(item.id)}>{item.car_company.name} - {item.name}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4">
                <div></div>
                <div></div>
                <div></div>
                <div>
                  <Button block htmlType={'submit'} type="primary" className={'!bg-green-600 !border-green-600'}>
                    <div className={'flex space-between items-center justify-center'}>
                      <FaSearchPlus/>
                      <div className={'ml-1'}>Tìm kiếm</div>
                    </div>
                  </Button>
                </div>
              </div>
            </Form>
          </div>


          <Table
              onChange={handleTableChange}
              sortField={sortField}
              sortOrder={sortOrder}
              // loading={isLoading}
              rowKey={'id'}
              size={'small'}
              dataSource={items}
              columns={columns}
              pagination={{
                position: ['bottomCenter'],
                pageSize: CONFIG.itemPerPage,
                total: totalItems,
                size: 'small',
                current: currentPage,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                },
              }}
          />
        </div>
      </div>
  );
}
