import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Api} from '../../services/api';
import {Button, Checkbox, Form, Input, message} from 'antd';
import {useForm} from 'antd/es/form/Form';
import {FaArrowCircleLeft} from 'react-icons/fa';
import {useHookQuery} from '../../hooks/use-hook-query';

export default function CustomerEditOrCreate() {
  const history = useHistory();
  const params = useParams();
  const [item, setItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [carCompanies, setCarCompanies] = useState([]);

  const [form] = useForm();

  const hookQuery = useHookQuery();

  useEffect(() => {
    if (params.id) {
      setIsLoading(true);
      Api.init().get('/customers/' + params.id).then(res => {
        setItem(res.data.data);
        form.setFieldsValue({
          ...res.data.data,
          car_company: res.data.data.car_company ? String(res.data.data.car_company.id) : null,
        });
      }).catch((err) => {
        console.error('DEBUG err', err);
        alert('Error while loading car models');
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      form.setFieldsValue({
        car_company: hookQuery.get('carCompany') || null,
      });
    }
  }, [params.id]);

  const onFinish = (values) => {
    console.log('Success:', values);
    setIsLoading(true);
    if (params.id) {
      Api.init().put('/customers/' + params.id, values).then(() => {
        message.success('Lưu thành công');
        history.push('/customers');
      }).catch(() => {
        message.error('Lưu thất bại, vui lòng thử lại!');
      }).finally(() => {
        setIsLoading(false);
      });
    } else {
      Api.init().post('/customers', values).then(() => {
        message.success('Tạo thành công');
        history.push('/customers');
      }).catch(() => {
        message.error('Tạo thất bại, vui lòng thử lại!');
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function getCarCompanies() {
    setIsLoading(true);
    Api.init().get('/car-companies', {
      itemPerPage: 1000,
    }).then(res => {
      setCarCompanies(res.data.data);
    }).catch(() => {
      alert('Error while loading car companies');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getCarCompanies();
  }, []);

  return (
      <div className={'p-5'}>
        <div className={'flex items-center justify-between'}>
          <h1 className={'text-3xl mb-0 pr-5'}>{params.id ?
              'SỬA THÔNG TIN' :
              'THÊM THÔNG TIN'}</h1>
          <Button onClick={() => {
            history.push('/customers');
          }} type={'primary'}
                  className={'!bg-green-600 !border-green-600'}>
            <div className={'flex space-between items-center'}>
              <FaArrowCircleLeft/>
              <div className={'ml-3'}>Quay lại</div>
            </div>
          </Button>
        </div>

        <div className="form-container" style={{
          background: '#FFF',
          padding: '25px',
          marginTop: '15px',
        }}>
          <Form
              form={form}
              name="basic"
              labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              initialValues={{remember: true}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <Form.Item
                label="Tên"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Trường này là bắt buộc.',
                  }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Trường này là bắt buộc.',
                  }]}
            >
              <Input autocomplete={'off'}/>
            </Form.Item>

            <Form.Item
                label="SĐT"
                name="phone_number"
            >
              <Input/>
            </Form.Item>

            <Form.Item
                valuePropName="checked"
                label="Vô hiệu hóa tài khoản"
                name="is_disabled"
            >
              <Checkbox/>
            </Form.Item>


            <Form.Item
                label="Địa chỉ"
                name="address"
            >
              <Input.TextArea/>
            </Form.Item>

            <Form.Item
                label="Ghi chú"
                name="note"
            >
              <Input.TextArea/>
            </Form.Item>

            <Form.Item
                label="Mật khẩu"
                name="password"
            >
              <Input.Password autocomplete={'off'}/>
            </Form.Item>

            <Form.Item
                label="Mật khẩu tạm thời"
                name="temp_password"
            >
              <Input.Password autocomplete={'off'}/>
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button block type="primary" htmlType="submit"
                      className={'!bg-green-600 !border-green-600'}>Lưu</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
  );
}
